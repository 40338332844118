
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import querystring from 'querystring';

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      mobile: Yup.string().required().label("Mobile"),
      password: Yup.string().min(4).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => {
            Swal.fire({
              text: "登入成功",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "确认",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              // Go to page after successfully login
              if (store.getters.currentUser.role == 'admin') {
                router.push({ name: "home" });
              } else {
                router.push({ name: "home" });
              }
            });
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 2000);
    };

    console.log(route.query.ssoToken)

    if (route.query.ssoToken) {
      store
      .dispatch(Actions.LOGIN_VIA_SSO, route.query.ssoToken)
      .then(() => {
        Swal.fire({
          text: "登入成功",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "确认",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(function () {
          // Go to page after successfully login
          if (store.getters.currentUser.role == 'admin') {
            router.push({ name: "home" });
          } else {
            router.push({ name: "home" });
          }
        });
      })
      .catch(() => {
        const [error] = Object.keys(store.getters.getErrors);
        Swal.fire({
          text: store.getters.getErrors[error],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });
    }

    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
